import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Banner.module.scss"

const BannerSlider = ({ strapiData }) => {

    return (
        <div className={styles.dedicatedBanner}>
            <Container className={styles.innerDedicated}>
                <div className={styles.dedicated}>
                    <img src={strapiData?.secImages?.[0]?.url} alt='bg image' className={styles.backgroundImg} />
                    <Container >
                        <div className={styles.dedicatedHeadingStatic}>
                            <h1
                                className={styles.subTitle}
                                dangerouslySetInnerHTML={{
                                    __html: strapiData?.title,
                                }}
                            />
                            <h2
                                className={styles.bannerHeading}
                                dangerouslySetInnerHTML={{
                                    __html: strapiData?.subTitle,
                                }}
                            />
                            <p
                                className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: strapiData?.description?.description,
                                }}
                            />
                        </div>
                        <Link to={strapiData?.buttons?.[0]?.url}>
                            <div className={`blackButtonBlueArrowBgother ${styles.btne}`}>
                                {strapiData?.buttons[0] && (
                                    <p>{strapiData?.buttons?.[0]?.title}</p>
                                )}
                            </div>
                        </Link>
                    </Container>
                </div>

            </Container>
          

        </div>
    )
}

export default BannerSlider