// extracted by mini-css-extract-plugin
export var bgColor = "Faqs2-module--bgColor--6b733";
export var customFaqBg = "Faqs2-module--customFaqBg--93883";
export var devOpsBg = "Faqs2-module--devOpsBg--9c216";
export var faqButton = "Faqs2-module--faqButton--4b71c";
export var faqsNum = "Faqs2-module--faqsNum--1dd83";
export var faqsSub = "Faqs2-module--faqsSub--abf75";
export var head = "Faqs2-module--head--47b57";
export var quesColor = "Faqs2-module--quesColor--176da";
export var quesColorSelected = "Faqs2-module--quesColorSelected--99eea";
export var section = "Faqs2-module--section--f8662";
export var serial = "Faqs2-module--serial--7ef63";
export var serial2 = "Faqs2-module--serial2--d86c9";
export var staffFaqBg = "Faqs2-module--staffFaqBg--a2f13";
export var textColor = "Faqs2-module--textColor--b08d2";