// extracted by mini-css-extract-plugin
export var SliderWidth = "WhyChooseOurIZ-module--SliderWidth--05501";
export var Trust = "WhyChooseOurIZ-module--Trust--aae73";
export var card = "WhyChooseOurIZ-module--card--286c4";
export var cardHeading = "WhyChooseOurIZ-module--cardHeading--e7a58";
export var desc = "WhyChooseOurIZ-module--desc--c9f91";
export var description = "WhyChooseOurIZ-module--description--92892";
export var heading = "WhyChooseOurIZ-module--heading--1beb7";
export var headingAndDesc = "WhyChooseOurIZ-module--headingAndDesc--25771";
export var iconContainer = "WhyChooseOurIZ-module--iconContainer--75181";
export var iconContainerLeft = "WhyChooseOurIZ-module--iconContainerLeft--d582c";
export var imgContainer = "WhyChooseOurIZ-module--imgContainer--3ec85";
export var portfolioArrowIcon = "WhyChooseOurIZ-module--portfolioArrowIcon--eb518";
export var portfolioArrowIconCover = "WhyChooseOurIZ-module--portfolioArrowIconCover--31d3a";
export var portfolioArrowRightIconCover = "WhyChooseOurIZ-module--portfolioArrowRightIconCover--64973";
export var sheildImgTop = "WhyChooseOurIZ-module--sheildImgTop--1f243";
export var shieldTopBannerImg = "WhyChooseOurIZ-module--shieldTopBannerImg--af0fd";