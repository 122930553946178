// extracted by mini-css-extract-plugin
export var SliderWidth = "FlexibleEngagement-module--SliderWidth--604a2";
export var Trust = "FlexibleEngagement-module--Trust--5aaba";
export var arrowPoint = "FlexibleEngagement-module--arrowPoint--3e22d";
export var blurInOut = "FlexibleEngagement-module--blurInOut--b9a86";
export var buton = "FlexibleEngagement-module--buton--cbb5a";
export var cardContent = "FlexibleEngagement-module--cardContent--981fa";
export var cardFooter = "FlexibleEngagement-module--cardFooter--f06bb";
export var cardHover = "FlexibleEngagement-module--cardHover--c0da4";
export var cards = "FlexibleEngagement-module--cards--b6ff3";
export var greyCard = "FlexibleEngagement-module--greyCard--a4dbf";
export var heading = "FlexibleEngagement-module--heading--bce93";
export var iconContainer = "FlexibleEngagement-module--iconContainer--02b3f";
export var iconContainerLeft = "FlexibleEngagement-module--iconContainerLeft--99254";
export var image2 = "FlexibleEngagement-module--image2--32042";
export var indDec = "FlexibleEngagement-module--indDec--dfe02";
export var newBtn = "FlexibleEngagement-module--newBtn--440bf";
export var points = "FlexibleEngagement-module--points--729c0";
export var portfolioArrowIcon = "FlexibleEngagement-module--portfolioArrowIcon--c398c";
export var portfolioArrowIconCover = "FlexibleEngagement-module--portfolioArrowIconCover--b0f59";
export var portfolioArrowRightIconCover = "FlexibleEngagement-module--portfolioArrowRightIconCover--9f793";
export var redCard = "FlexibleEngagement-module--redCard--a3bee";
export var sheildImgTop = "FlexibleEngagement-module--sheildImgTop--3ee46";
export var shieldTopBannerImg = "FlexibleEngagement-module--shieldTopBannerImg--48264";
export var skill = "FlexibleEngagement-module--skill--55eb3";
export var text = "FlexibleEngagement-module--text--5724b";