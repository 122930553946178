import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import Faqs from "../../components/augmented-reality/Faqs2"
import MainLayout from "../../layouts/MainLayout3"
import Banner from "../../components/emerging-technologies/Banner"
import TrustInvoZone from "../../components/application-engineering/OverCountriesProduct"
import Transformative from "../../components/augmented-reality/WorkPortfolio"
import WordsFromClients from "../../components/augmented-reality/WordsFromClients"
import WhyChooseOurIz from "../../components/augmented-reality/WhyChooseOurIZ"
import OurEmergingTechnology from "../../components/augmented-reality/OurEmergingTechnology"
import ScopOfEmergingTech from "../../components/nlp-services/NLPExpertise"
import PerfectEngagement from "../../components/augmented-reality/PerfectEngagement"
import SEORevamp from "../../components/common/SEO_Revamp"
import FlexibleEngagement from "../../components/augmented-reality/FlexibleEngagement"

export const Head = () => {
  let headSchema = []
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={'metaTitle'}
        description={'metaDescription'}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}

const VirtualRealityServicesPage = ({ data }) => {
  {console.log(data,"dataa")}
  const heroSection = data?.strapiPage?.sections[0]
  const ourEmergingTechnology = data?.strapiPage?.sections[2]
  const whyChoose = data?.strapiPage?.sections[3]
  const scopeOfEmerging = data?.strapiPage?.sections[4]
  const waysWeEngage = data?.strapiPage?.sections[5]
  const flexibleEngagement = data?.strapiPage?.sections[6]
  const transformative = data?.strapiPage?.sections[7]
  const wordsFromClient = data?.strapiPage?.sections[8]
  const faqs = data?.strapiPage?.sections[9]  

 

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })


  return (
    <MainLayout bgChanged={true} schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <TrustInvoZone hideCurve={true} />
      <OurEmergingTechnology strapiData={ourEmergingTechnology} />
      <WhyChooseOurIz strapiData={whyChoose} />
      <ScopOfEmergingTech strapiData={scopeOfEmerging} />
      <FlexibleEngagement strapiData={waysWeEngage}/>
      <PerfectEngagement strapiData={flexibleEngagement}/>
      <Transformative strapiData={transformative} />
      <WordsFromClients strapiData={wordsFromClient}  />
      <Faqs strapiData={faqs} pageName="Augmented Reality Services" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "augmented-reality-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          jsonData {
           industryServices{
            Services
            }
          }
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
          
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default VirtualRealityServicesPage;
