// extracted by mini-css-extract-plugin
export var SliderWidth = "WordsFromClients-module--SliderWidth--8d01c";
export var Trust = "WordsFromClients-module--Trust--a7413";
export var blueText = "WordsFromClients-module--blueText--bbd12";
export var clientContent = "WordsFromClients-module--clientContent--34240";
export var coma = "WordsFromClients-module--coma--7a7ad";
export var content = "WordsFromClients-module--content--9d853";
export var customModal = "WordsFromClients-module--customModal--eb50d";
export var dec = "WordsFromClients-module--dec--c916a";
export var description = "WordsFromClients-module--description--533a0";
export var designation = "WordsFromClients-module--designation--8790f";
export var dot = "WordsFromClients-module--dot--41f91";
export var heading = "WordsFromClients-module--heading--b7e56";
export var headingContainer = "WordsFromClients-module--headingContainer--85c27";
export var iconContainer = "WordsFromClients-module--iconContainer--6f6f9";
export var iconContainerLeft = "WordsFromClients-module--iconContainerLeft--568e4";
export var logos = "WordsFromClients-module--logos--8d0fd";
export var logosBg = "WordsFromClients-module--logosBg--78179";
export var name = "WordsFromClients-module--name--14645";
export var playBtn = "WordsFromClients-module--playBtn--19c4e";
export var portfolioArrowIcon = "WordsFromClients-module--portfolioArrowIcon--4045c";
export var portfolioArrowIconCover = "WordsFromClients-module--portfolioArrowIconCover--30e58";
export var portfolioArrowRightIconCover = "WordsFromClients-module--portfolioArrowRightIconCover--ae5df";
export var view = "WordsFromClients-module--view--49a77";