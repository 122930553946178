// extracted by mini-css-extract-plugin
export var Trust = "WorkPortfolio-module--Trust--b7116";
export var arrowIcon = "WorkPortfolio-module--arrowIcon--9e46a";
export var arrowImage = "WorkPortfolio-module--arrowImage--7489f";
export var arrowLink = "WorkPortfolio-module--arrowLink--3d930";
export var btn = "WorkPortfolio-module--btn--68657";
export var btn2 = "WorkPortfolio-module--btn2--eb0c7";
export var caseImages = "WorkPortfolio-module--caseImages--4513d";
export var clientImgs = "WorkPortfolio-module--clientImgs--9c5e5";
export var content = "WorkPortfolio-module--content--41280";
export var description = "WorkPortfolio-module--description--a2b95";
export var headSearch = "WorkPortfolio-module--headSearch--0e7fe";
export var heading = "WorkPortfolio-module--heading--18d53";
export var logoImages = "WorkPortfolio-module--logoImages--82164";
export var logos = "WorkPortfolio-module--logos--3ddac";
export var rowGap = "WorkPortfolio-module--rowGap--f1d00";
export var talkBtn = "WorkPortfolio-module--talkBtn--12ca9";