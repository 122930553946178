// extracted by mini-css-extract-plugin
export var description = "OurEmergingTechnology-module--description--268b1";
export var heading = "OurEmergingTechnology-module--heading--32aaa";
export var headingContainer = "OurEmergingTechnology-module--headingContainer--d11b0";
export var pointCard = "OurEmergingTechnology-module--pointCard--634e4";
export var pointTitle = "OurEmergingTechnology-module--pointTitle--1fa20";
export var rightCard = "OurEmergingTechnology-module--rightCard--07940";
export var rightCardDescription = "OurEmergingTechnology-module--rightCardDescription--580b0";
export var rightCardHeading = "OurEmergingTechnology-module--rightCardHeading--40fc1";
export var sectionContainer = "OurEmergingTechnology-module--sectionContainer--0e2cc";
export var selected = "OurEmergingTechnology-module--selected--06f6e";
export var smallCard = "OurEmergingTechnology-module--smallCard--e1c39";
export var title = "OurEmergingTechnology-module--title--de22c";